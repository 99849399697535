import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { updateSelectedTab } from '../../../../redux/features/tabsSlice'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';

import StarIcon from '@mui/icons-material/Star';
import StoreIcon from '@mui/icons-material/Store';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import PetsIcon from '@mui/icons-material/Pets';

import SubActivities from './tabs/activites';
import Services from './tabs/services';
import CloseBy from './tabs/closeby';

import i18n from '../../../../i18n/i18n';

const Activities = () => {
  const { t } = i18n;
  
  const currentTab = useSelector((state) => state.tabs.currentTab)
  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    dispatch(updateSelectedTab(newValue));
  };

  return (
    <Fragment>
      <Divider sx={{mt: {xs:5, md:5}, mb:4}} id="activites">
        <Stack>
          <Typography variant='h5'>
            {t('activities.secondary')}
          </Typography>
          <Typography 
              variant='h4'
              sx={{
                  letterSpacing:'-0.05em',
                  fontWeight:600,
              }}
          >
            {t('activities.main')}
          </Typography>
        </Stack>
      </Divider>
      <Container maxWidth='xl'>
        <TabContext sx={{width:'100%'}} value={currentTab}>
          <TabList sx={{width:'100%'}} centered onChange={handleChange} aria-label="Activites, commodities and closeby">
            <Tab icon={<StarIcon />} label={t('activities.activities')} value="activities" />
            <Tab icon={<StoreIcon />} label={t('activities.services')}  value="services" />
            <Tab icon={<LocationOnIcon />} label={t('activities.closeby')}  value="closeby" />
          </TabList>
          <TabPanel value="activities"><SubActivities/></TabPanel>
          <TabPanel value="services"><Services/></TabPanel>
          <TabPanel value="closeby"><CloseBy/></TabPanel>
        </TabContext>
        <Box sx={{display:'flex', mt:3, justifyContent:'center'}}>
          <Grid container spacing={2}>
            <Grid container item xs={12} md={6} justifyContent="center">
              <FormatColorResetIcon sx={{fontSize:'1.5em'}}/>
              <Typography fontSize='1.1em' sx={{ml:1}}>{t('activities.waterNotDrinkable')}</Typography>
            </Grid>
            <Grid container item xs={12} md={6} justifyContent="center">
              <PetsIcon sx={{fontSize:'1.5em'}}/>
              <Typography fontSize='1.1em' sx={{ml:1}}>{t('activities.petsAllowed')}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Divider sx={{mt: 5}} />
    </Fragment>

  );
}
export default Activities;