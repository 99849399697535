const en = 
{
    translation: {
        navigation: {
            home: "Home",
            activities: "Activities",
            reservationsAndPrices: "Reservation & prices",
            campgroundMap: "Campground map",
            photos: "Photos",
            contactus: "Contact us",
        },
        slogan: {
            secondary: "Dream destination",
            main: "Bordered by the sea",
        },
        sites:{
            description:"The Camping plage Trois-Pistoles is the destination for coastal camping par excellence",
            traveler:"122 traveler campsites",
            threeServices:"69 three services",
            twoServices:"48 two services",
            noService:"5 no service on the seaside",
            seasonal:"104 seasonal campsites",
        },
        activities:{
            secondary:"Activities and services",
            main:"At hand",

            activities:"Activities",
            beach:"Direct beach access",
            waterplayground:"Water playground",
            eventsAndAnimations:"Events and animations",
            playground:"Playground",
            hockeyRink:"Hockey rink",
            tableTennis:"Table tennis",
            beachVolleyball:"Beach volleyball",
            horseshoeGames:"Horseshoe games",
            petanque:"Petanque",

            services:"Services",
            convenienceStore:"Convenience store",
            communityHall:"Community Hall",
            wifi:"High Speed Wi-Fi",
            woodForSale:"Wood for sale",
            iceForSale:"Ice for sale",
            laundryRoom:"Laundry room",
            showers:"Showers",
            toilet:"Toilets",
            garden:"Garden",

            closeby:"Close by",
            kayakingtrip:"Kayaking trip",
            bikePath:"Bike path",
            bicNationalPark:"Bic national park",
            basqueCheeseFactory:"Basque cheese factory",
            shoppingMall:"Shopping mall",

            waterNotDrinkable:"Please note that the water is not drinkable.",
            petsAllowed:"Pets on a leash are allowed",
        },
        reservations:{
            secondary:"Reservation & Prices",
            main:"Terms",

            phone:"By phone from 9:00 a.m. to 9:00 p.m.",
            noDirectDebit:"By credit card, without prepayment.",
            creditCard:"Visa, Mastercard and Interac are accepted.",
            arrivalAndDeparture:"Arrival and departure are for 1:00 p.m.",
            registration:"Registration must be done before 7:30 p.m., otherwise the campsite will become available for other campers.",
            cancelation:"Cancellations are free of charge 7 days before the reservation. Otherwise, one night will be charged",
            reserveNow:"Reserve Now",
        },
        prices:{
            secondary:"Prices",
            main:"Travelers and seasonals",

            services:"Services",
            perNight:"Per night",
            perWeek:"Per week",
            perSeason:"Per season",

            noService:"No service",
            twoServices:"Two services",
            threeServices:"Three services (30 & 50 amps.)",

            guests:"Guests",
            children:"Children",
            adults:"Adults",
        },
        campgroundMap:{
            secondary:"Campground",
            main:"Map",
        },
        photos:{
            secondary:"Gallery",
            main:"Photos",
        },
        contactUs:{
            secondary:"Contact us",
            main:"Informations",
            season:"Season",
            seasonBegins:"Opening date : ",
            seasonEnds:"Closing date : ",
            officeHours:"Reception open from 9:00 a.m. to 9:00 p.m."
        },
        footer:{
            establishmentNumber:"Tourisme Québec establishment number : "
        },
        tooltips:{
            changeLanguage:"Français"
        },
        notfound:{
            main:"🌊 Oh no, you've backed the RV into the sea! 🌊",
            secondary:"You've been distracted by the splendid sunset and you have fallen into the water...",
            error:"404 Error",
            pageDosentExist:"The page that you are looking for dosen't exist.",
            towMeBackHome:"Tow me back home",
        }
    }
}

export default en;