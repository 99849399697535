const fr = 
{
    translation: {
        navigation: {
            home: "Accueil",
            activities: "Activités",
            reservationsAndPrices: "Réservation & tarifs",
            campgroundMap: "Plan du site",
            photos: "Photos",
            contactus: "Nous joindre",
        },
        slogan: {
            secondary: "Destination de rêve",
            main: "Bordée par la mer",
        },
        sites:{
            description:"Le Camping plage Trois-Pistoles est la destination du camping littoral par excellence.",
            traveler:"122 sites voyageurs",
            threeServices:"69 trois services",
            twoServices:"48 deux services",
            noService:"5 sans service au bord de la mer",
            seasonal:"104 sites saisonniers",
        },
        activities:{
            secondary:"Activités et services",
            main:"À portée de main",

            activities:"Activités",
            beach:"Accès direct à la plage",
            waterplayground:"Jeux d'eau",
            eventsAndAnimations:"Événements et animations",
            playground:"Terrain de jeux",
            hockeyRink:"Patinoire",
            tableTennis:"Tennis de table",
            beachVolleyball:"Volleyball de plage",
            horseshoeGames:"Jeux de fers",
            petanque:"Pétanque",

            services:"Services",
            convenienceStore:"Dépanneur",
            communityHall:"Salle communautaire",
            wifi:"Internet haute vitesse",
            woodForSale:"Bois en vente",
            iceForSale:"Glace en vente",
            laundryRoom:"Buanderie",
            showers:"Douches",
            toilet:"Toilettes",
            garden:"Potager",

            closeby:"À proximité",
            kayakingtrip:"Excursion en kayak",
            bikePath:"Piste cyclable",
            bicNationalPark:"Parc national du Bic",
            basqueCheeseFactory:"Fromagerie des Basques",
            shoppingMall:"Centre commercial",

            waterNotDrinkable:"À noter que l'eau n'est pas potable.",
            petsAllowed:"Les animaux en laisse sont permis",
        },
        reservations:{
            secondary:"Réservation & tarifs",
            main:"Modalités",

            phone:`Par téléphone de 9h00 à 21h00.`,
            noDirectDebit:"Par carte de crédit et ce sans prélèvement.",
            creditCard:"Visa, Mastercard et Interac sont acceptés.",
            arrivalAndDeparture:"L’arrivée et le départ se font pour 13h00.",
            registration:"Enregistrement avant 19h30, sans quoi le terrain deviendra disponible pour d'autres campeurs.",
            cancelation:"Annulation sans frais 7 jours avant la réservation. Autrement, une nuit de frais seront prélevés.",
            reserveNow:"Réserver",
        },
        prices:{
            secondary:"Tarifs",
            main:"Voyageurs et saisonniers",

            services:"Services",
            perNight:"Par nuit",
            perWeek:"Par semaine",
            perSeason:"Par saison",

            noService:"Sans service",
            twoServices:"Deux services",
            threeServices:"Trois services (30 & 50 amps.)",

            guests:"Visiteurs",
            children:"Enfants",
            adults:"Adultes",
        },
        campgroundMap:{
            secondary:"Plan",
            main:"Du site",
        },
        photos:{
            secondary:"Galerie",
            main:"Photos",
        },
        contactUs:{
            secondary:"Nous joindre",
            main:"Informations",
            seasonBegins:"Date d'ouverture : ",
            seasonEnds:" Date de fermeture : ",
            officeHours:"Réception ouverte de 9h00 à 21h00."
        },
        footer:{
            establishmentNumber:"Numéro d’établissement Tourisme Québec : "
        },
        tooltips:{
            changeLanguage:"English"
        },
        notfound:{
            main:"🌊 Oh non, vous avez reculé le VR dans la mer ! 🌊",
            secondary:"Le splendide couché de soleil vous a déconcentré et vous êtes tombé à l'eau...",
            error:"Erreur 404",
            pageDosentExist:"La page que vous cherchez n'existe malheureusement pas.",
            towMeBackHome:"Remorquez-moi à l'accueil",
        }
    }
}

export default fr;