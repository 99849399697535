import * as React from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import KayakingIcon from '@mui/icons-material/Kayaking';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import ParkIcon from '@mui/icons-material/Park';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import BusinessIcon from '@mui/icons-material/Business';

import i18n from '../../../../../i18n/i18n';

const CloseBy = () => {
    const { t } = i18n;

    return(
        <Grid container spacing={2} sx={{mt:4}}>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <KayakingIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.kayakingtrip')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <DirectionsBikeIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.bikePath')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <ParkIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.bicNationalPark')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <RestaurantIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.basqueCheeseFactory')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={12} md={4} justifyContent="center">
                <Stack>
                    <BusinessIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.shoppingMall')}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default CloseBy;