import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography';

import i18n from '../../../i18n/i18n';

const Footer = () => {
    const { t } = i18n;
    
    return(
        <footer>
            <Container sx={{mt:10}}>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    Camping Plage Trois-Pistoles © {new Date().getFullYear()}
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    {t('footer.establishmentNumber')} 202425
                </Typography>
            </Container>
        </footer>
    );
}


export default Footer