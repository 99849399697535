import * as React from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import HouseIcon from '@mui/icons-material/House';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import ShowerIcon from '@mui/icons-material/Shower';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WifiIcon from '@mui/icons-material/Wifi';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import WcIcon from '@mui/icons-material/Wc';
import YardIcon from '@mui/icons-material/Yard';

import i18n from '../../../../../i18n/i18n';

const Services = () => {
    const { t } = i18n;

    return(
        <Grid container spacing={2} sx={{mt:4}}>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <StorefrontIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography>
                        {t('activities.convenienceStore')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <HouseIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                    {t('activities.communityHall')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <WifiIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.wifi')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <FireplaceIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.woodForSale')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <AcUnitIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.iceForSale')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <LocalLaundryServiceIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.laundryRoom')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <ShowerIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.showers')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <WcIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.toilet')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={12} md={4} justifyContent="center">
                <Stack>
                    <YardIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.garden')}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default Services;