import { Fragment, lazy } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import pages from '../../../../components/const/Pages'

import Loadable from '../../../../ui-components/Loadable';

const DesktopMenu = Loadable(lazy(() => import('./menu/DesktopMenu')));
const MobileMenu = Loadable(lazy(() => import('./menu/MobileMenu')));

const Navigation = () => {
  const { pathname, hash } = useLocation();
  let currentpath = pathname + hash;
  
  return (
    <Fragment>
      <Box sx={{display: { xs: "none", md: "flex" } }}>
        <DesktopMenu pages={pages} currentPath={currentpath}/>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" } }}>
        <MobileMenu pages={pages} currentPath={currentpath}/>
      </Box>
    </Fragment>
  )
}

export default Navigation;