import * as React from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


import PhoneIcon from '@mui/icons-material/Phone';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';

import i18n from '../../../../i18n/i18n';

import Reservation from '../../../../assets/Reservation.jpg'

const Img = styled('img')({
  maxWidth: '100%',
  maxHeight: '90%',
});


const Reservations = () => {
  const { t } = i18n;

  return(
    <Container maxWidth='xl' sx={{mt: {xs:5, md:10}}}>
      <Grid container>
        <Grid item xs={12} md={7} sx={{pr:{md:20}}}>
          <Stack>
            <Typography variant='h5' id='reservations-tarifs'>
              {t('reservations.secondary')}
            </Typography>
            <Typography 
                variant='h4'
                sx={{
                    letterSpacing:'-0.05em',
                    fontWeight:600,
                    mb:2
                }}
            >
              {t('reservations.main')}
            </Typography>
            <Box sx={{display:'flex'}}>
              <PhoneIcon sx={{fontSize:'1.5em'}}/>
              <Typography fontSize='1.1em' sx={{ml:1}}>{t('reservations.phone')} (<Link href='tel:418-851-2403'>418-851-2403</Link>)</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
              <MoneyOffIcon sx={{fontSize:'1.5em'}}/>
              <Typography fontSize='1.1em' sx={{ml:1}}>{t('reservations.noDirectDebit')}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
              <CreditCardIcon sx={{fontSize:'1.5em'}}/>
              <Typography fontSize='1.1em' sx={{ml:1}}>{t('reservations.creditCard')}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
              <AirlineStopsIcon sx={{fontSize:'1.5em'}}/>
              <Typography fontSize='1.1em' sx={{ml:1}}>{t('reservations.arrivalAndDeparture')}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
              <AccessTimeIcon sx={{fontSize:'1.5em'}}/>
              <Typography fontSize='1.1em' sx={{ml:1}}>{t('reservations.registration')}</Typography>
            </Box>
            <Box sx={{display:'flex', }}>
              <CancelIcon sx={{fontSize:'1.5em'}}/>
              <Typography fontSize='1.1em' sx={{ml:1}}>{t('reservations.cancelation')}</Typography>
            </Box>
            <Box sx={{display:'flex', mt:5}}>
              <Button component={Link} href='tel:418-851-2403' variant="contained">{t('reservations.reserveNow')}</Button>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} md={5} sx={{mt:{xs:5, md:0}}}>
          <Img sx={{maxHeight:{xs:'100%', md:'100%'}}} alt={t('reservations.reserveNow')} src={Reservation} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Reservations;