import React from 'react';
import { HashLink } from 'react-router-hash-link';

import Box from '@mui/material/Box';
import ScrollWithOffset from "../scroll/ScrollWithOffSet";

const ScrollDown = ({to}) => 
<Box
  component={HashLink}
  smooth
  to={to}
  scroll={el => ScrollWithOffset(el)}

  sx={{
      position: 'relative',
      zIndex: '10',
      top: 0,
      left: '47.5%',
      width: '3em',
      height: '3em',
      borderLeft: '0.8em solid #fff',
      borderBottom: '0.8em solid #fff',
      transform: 'rotateZ(-45deg)',
      animationName: 'sdb06',
      animationDuration: '10s',
      animationIterationCount:'infinite',
      boxSizing: 'border-box',
      '@keyframes sdb06': {
        '0%': {
          transform: 'rotateY(0) rotateZ(-45deg)',
          opacity: 1,
        },
        '100%': {
          transform: 'rotateY(720deg) rotateZ(-45deg)',
          opacity: 1,
        }
      },
      '&:hover': {
        cursor:'pointer'
      }
  }}
/>


export default ScrollDown;