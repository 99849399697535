import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import TerrainIcon from '@mui/icons-material/Terrain';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ForestIcon from '@mui/icons-material/Forest';

import i18n from '../../../i18n/i18n';
import ObservationPointSunset from '../../../assets/ObservationPointSunset.jpg';

const Img = styled('img')({

});


const Sites = () => {
  const { t } = i18n;

  return(
    <Container maxWidth='xl' sx={{mt: {xs:10, md:10}}}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Img alt="Accueil" sx={{maxWidth: {xs:'100%', md:'90%'},}} src={ObservationPointSunset}/>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Typography fontSize='1.3em'>{t('sites.description')}</Typography>
            <Box sx={{ml:2}}>
            <Stack spacing={0}>
              <Box sx={{display:'flex', alignItems:'center'}}>
                <TerrainIcon sx={{fontSize:'2.5em'}}/>
                <Typography fontSize='2em' sx={{ml:1}}>{t('sites.traveler')}</Typography>
              </Box>
              <Box sx={{display:'flex', alignItems:'center'}}>
                <AirportShuttleIcon sx={{fontSize:'2.5em'}}/>
                <Typography fontSize='1.5em' sx={{ml:1}}>{t('sites.threeServices')}</Typography>
              </Box>
              <Box sx={{display:'flex', alignItems:'center'}}>
                <RvHookupIcon sx={{fontSize:'2.5em'}}/>
                <Typography fontSize='1.5em' sx={{ml:1}}>{t('sites.twoServices')}</Typography>
              </Box>
              <Box sx={{display:'flex', alignItems:'center'}}>
                <LocalFireDepartmentIcon sx={{fontSize:'2.5em'}}/>
                <Typography fontSize='1.5em' sx={{ml:1}}>{t('sites.noService')}</Typography>
              </Box>
              <Box sx={{display:'flex', alignItems:'center'}}>
                <ForestIcon sx={{fontSize:'2.5em'}}/>
                <Typography fontSize='2em' sx={{ml:1}}>{t('sites.seasonal')}</Typography>
              </Box>
            </Stack>
            </Box>
          </Stack>
        </Grid>  
      </Grid>
    </Container>
  );
}

export default Sites;