import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen : false,
  src: '',
  title:'',
}

export const imgModalSlice = createSlice({
  name: 'imgmodal',
  initialState,
  reducers: {
    open: (state, action) => {
      state.isOpen = action.payload;
    },
    updateImgInformations: (state, action) => {
      state.src = action.payload.src;
      state.title = action.payload.title;
    }
  }
})

export const { open, updateImgInformations } = imgModalSlice.actions;
export default imgModalSlice.reducer;