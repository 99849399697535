import { createTheme } from "@mui/material";

const Theme = createTheme({
    typography: {
      fontFamily: [
        "Montserrat",
        "sans-serif"
      ].join(",")
    },
    palette: {
      primary: {
        main: "#EF6B5B",
      },
      secondary: {
        main: "#FF8866",
      },
      background: {
        paper: "#f2f2f2",
      },
      text: {
        primary: "#11111",
      },
    },
  });

export default Theme;