import {useEffect , Fragment} from 'react';
import { useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Waves from '../../layout/main-layout/header/Waves';
import ScrollDown from '../../components/links/Scrolldown';

import Slogan from './sections/Slogan';
import Sites from './sections/Sites';
import Activities from './sections/activities';
import Reservations from './sections/reservations-prices/Reservations';
import Prices from './sections/reservations-prices/Prices';
import CampgroundMap from './sections/CampgroundMap';
import Photos from './sections/Photos';
import ContactInformations from './sections/ContactInformations';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
  
const Home = () => {
  const pageTitle = useSelector((state) => state.sections.pageTitle);

  useEffect(() =>{
    document.title = capitalizeFirstLetter(pageTitle);
  });

  return (
    <Fragment>
      <Waves title='Camping plage Trois-Pistoles' subItem={<ScrollDown to={'#slogan'}/>}/>
      <Container maxWidth='false' sx={{pt:4}} disableGutters>
        <Stack>
          <Slogan/>
          <Sites />
          <Activities />
          <Reservations />
          <Prices />
          <CampgroundMap />
          <Photos />
          <ContactInformations />
        </Stack>
      </Container>
    </Fragment>
  )
}

export default Home;