import i18n from "../../i18n/i18n";

const Pages = () => {
  const { t } = i18n;
  const pages = [
    { title: t('navigation.home'), top:true, section: 'accueil', url: "/#" },
    { title: t('navigation.activities'), top:false, section: 'activites',  url: "/#activites" },
    { title: t('navigation.reservationsAndPrices'), top:false, section: 'reservations-et-tarifs',  url: "/#reservations-tarifs" },
    { title: t('navigation.campgroundMap'), top:false, section: 'plan-du-site',  url: "/#plan-du-site" },
    { title: t('navigation.photos'), top:false, section: 'photos',  url: "/#photos" },
    { title: t('navigation.contactus'), top:false, section: 'nous-joindre',  url: "/#nous-joindre" },
  ]
  return pages
}

export default Pages;