import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ThreeStars from '../../../assets/Camp_3.png';

import i18n from '../../../i18n/i18n';

const Slogan = () => {
    const { t } = i18n;
    
    return(
    <Container maxWidth='xl'>
        <Box
            id='slogan'
            sx={{
                display:'flex',
                textAlign:'center',
                justifyContent:'center'
            }}
        >
            <Stack>
                <Typography variant='h3'>
                {t('slogan.secondary')}
                </Typography>
                <Typography 
                    variant='h2'
                    sx={{
                        letterSpacing:'-0.05em',
                        fontWeight:600,
                    }}
                >
                    {t('slogan.main')}
                </Typography>
                <Box sx={{mt:10}}>
                    <Box
                        component="img"
                        alt="3 étoiles"
                        src={ThreeStars}
                        sx={{
                            width:'20%',
                        }}
                    />
                </Box>
            </Stack>
        </Box>
    </Container>
    );
}

export default Slogan;